import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import {
  InstantSearch,
  Configure,
  connectRefinementList,
  connectHits
} from 'react-instantsearch-dom';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { connect } from 'react-redux';
import { trackProductClick } from '../../utils/tracking';

import ProductCard from '../product-card/algolia';
import Carousel from '../../molecules/product-carousel';

const Product = styled(ProductCard)`
  width: 50%;
  text-align: center;
  margin-top: 4rem;

  @media screen and (min-width: ${(props) => props.theme.breakpoint.small}) {
    width: 25%;
  }

  &.circle {
    figure {
      border-radius: 50%;
    }
  }
`;

const NoSlider = styled('span', { shouldForwardProp: (prop) => prop !== 'numChildren' })`
  display: flex;
  flex-wrap: wrap;
  ${({ numChildren }) => (numChildren < 4 ? css`
    justify-content: center;
  ` : '')}
`;

const VirtualRefinementList = connectRefinementList(() => null);

// Via: https://www.algolia.com/doc/api-reference/widgets/refinement-list/react/#props
VirtualRefinementList.propTypes = {
  attribute: PropTypes.string.isRequired,
  defaultRefinement: PropTypes.arrayOf(PropTypes.string),
  facetOrdering: PropTypes.bool, // default: true
  operator: PropTypes.string, // default: 'or'
  limit: PropTypes.number, // default: 10
  showMore: PropTypes.bool, // default: false
  showMoreLimit: PropTypes.number, // default: 20
  searchable: PropTypes.bool, // default: true
  transformItems: PropTypes.func, // default: null
  translations: PropTypes.shape({
    showMore: PropTypes.bool,
    noResults: PropTypes.string,
    submitTitle: PropTypes.string,
    resetTitle: PropTypes.string,
    placeholder: PropTypes.string
  }) // default: {}
};

const setStyle = (styleName, index) => {
  if (styleName === 'both') {
    return index % 2 ? 'circle' : 'square';
  }
  return styleName;
};

const createRefinementAttributes = (search) => {
  /* on home page, search is an empty object
   * Update on above: this is due to no starting '/'
   * TODO is there a possibility that both of these RefinementLists
   * would need to be active on a single Algolia query?
   * Update: In order to correctly render taxon-products for page-types
   * other than shop, pageValue and HM data are combined to create a default Refinement List.
  */
  let list = [];
  let defaultRefinement = [];
  if (search) {
    if (search.hierarchicalMenu && search.hierarchicalMenu['categories_slug.lvl0'] !== '') {
      list = Object.keys(search.hierarchicalMenu);
      defaultRefinement = Object.values(search.hierarchicalMenu);
      // Respelt splitHeirarchies in splitHierarchies for consistency
      const splitHierarchies = search.hierarchicalMenu['categories_slug.lvl0'].split('>');
      if (splitHierarchies.length > 1) {
        // TODO this is a hack, which occurs in the algolia utils helpers file
        list.splice(0, 1, `categories_slug.lvl${splitHierarchies.length - 1}`);
      }
    }
    if (search.pageTypeRefinement) {
      list = [...list, ...Object.keys(search.pageTypeRefinement)];
      defaultRefinement = [...defaultRefinement, ...Object.values(search.pageTypeRefinement)];
    }
    return list.map((listName, index) => (
      <VirtualRefinementList
        attribute={listName}
        key={listName}
        defaultRefinement={[defaultRefinement[index]]}
      />
    ));
  }
  return null;
};

const TaxonProductWrapper = (props) => {
  const { hits } = props;

  const [carouselRef, setCarouselRef] = useState(null);

  const onCarouselRef = useCallback((node) => {
    if (node !== null) {
      setCarouselRef(node);
    }
  }, []);

  const carouselSettings = {
    groupCells: true,
    prevNextButtons: true,
    pageDots: false,
    cellAlign: 'left'
  };

  const length = carouselRef?.selectedCells?.length ?? 1;

  if (props.isSlider && (hits.length >= length)) {
    return (
      <Carousel
        flickityRef={onCarouselRef}
        type="taxon-products"
        options={carouselSettings}
        reloadOnUpdate
      >
        {
          hits && hits.map((item, i) => (
            <ProductCard
              taxonProducts
              key={item.slug}
              product={item}
              index={i + 1}
              showQuickShop={false}
              module="taxon_products"
              onClick={(e) => {
                trackProductClick({ item });
                const isWishlist = e.target.dataset.id === 'wishlist';
                if (isWishlist) {
                  e.preventDefault();
                  return false;
                }
                return true;
              }}
            />
          ))
        }
      </Carousel>
    );
  }

  return (
    <NoSlider numChildren={hits.length}>
      {
        hits && hits.map((item, i) => (
          <Product
            taxonProducts
            key={`${item.rank}_${item.objectID}`}
            product={item}
            index={i + 1}
            showQuickShop={false}
            module="taxon_products"
            className={setStyle(props.style, i)}
            onClick={(e) => {
              trackProductClick({ item });
              const isWishlist = e.target.dataset.id === 'wishlist';
              if (isWishlist) {
                e.preventDefault();
                return false;
              }
              return true;
            }}
          />
        ))
      }
    </NoSlider>
  );
};

TaxonProductWrapper.defaultProps = {
  isSlider: false,
  style: '',
  hits: []
};

TaxonProductWrapper.propTypes = {
  // Algolia hits optional object[] via:
  // https://www.algolia.com/doc/api-reference/widgets/hits/react/#connector-param-provided-hits
  hits: PropTypes.arrayOf(PropTypes.object),
  isSlider: PropTypes.bool,
  style: PropTypes.string
};

const HitsComponent = connectHits(TaxonProductWrapper);

const TaxonProductsAlgolia = (props) => (
  <InstantSearch
    searchClient={props.searchClient}
    searchState={props.searchState}
    createURL={props.createURL}
    indexName={props.indexName}
    {...props}
  >
    {createRefinementAttributes(props.searchState)}
    { props.isAlgoliaPersonalizationEnabled ? (
      <Configure
        hitsPerPage={props.productLimit}
        enablePersonalization={!!props.userID || false}
        userToken={props.userID}
      />
    ) : (
      <Configure hitsPerPage={props.productLimit} />
    )}
    <HitsComponent
      // TODO: the only props this actually takes: hits, insights
      {...props}
    />
  </InstantSearch>
);

TaxonProductsAlgolia.defaultProps = {
  brand: null,
  edit: null,
  children: null,
  miniFilters: {},
  trend: null,
  productLimit: 4,
  onSearchStateChange: null,
  resultsState: null,
  searchState: null,
  hits: {},
  userID: null,
  isAlgoliaPersonalizationEnabled: false
};

TaxonProductsAlgolia.propTypes = {
  brand: PropTypes.object,
  children: PropTypes.node,
  createURL: PropTypes.func.isRequired,
  edit: PropTypes.string,
  miniFilters: PropTypes.object,
  indexName: PropTypes.string.isRequired,
  // Algolia onSearchStateChange optional function via:
  // https://www.algolia.com/doc/api-reference/widgets/instantsearch/react/#widget-param-onsearchstatechange
  onSearchStateChange: PropTypes.func,
  // Algolia resultsState optional object via:
  // https://www.algolia.com/doc/api-reference/widgets/instantsearch/react/#widget-param-resultsstate
  resultsState: PropTypes.object,
  searchClient: PropTypes.object.isRequired,
  // Algolia searchState optional object via:
  // https://www.algolia.com/doc/api-reference/widgets/instantsearch/react/#widget-param-searchstate
  searchState: PropTypes.object,
  trend: PropTypes.string,
  productLimit: PropTypes.number,
  // Algolia hits optional object[] via:
  // https://www.algolia.com/doc/api-reference/widgets/hits/react/#connector-param-provided-hits
  hits: PropTypes.object,
  userID: PropTypes.number,
  isAlgoliaPersonalizationEnabled: PropTypes.bool
};

const mapStateToProps = (state) => ({
  userID: state.profile.id
});

export default connect(mapStateToProps, null)(TaxonProductsAlgolia);
