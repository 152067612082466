import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import styled from '@emotion/styled';

import * as helpers from '../../organisms/algolia-plp/utils/helpers';
import searchClient from '../../utils/algolia';

import Typography from '../../atoms/typography';

import Picture from '../../atoms/cms-picture';
import Ruler from '../../atoms/ruler';

import TaxonProductsAlgolia from './algolia';

const Container = styled('section', { shouldForwardProp: (prop) => prop !== 'is_slider'})`
  padding: 0 ${({ isSlider, theme }) => isSlider ? '0' : theme.modularScale.small};
  width: 100%;

  a {
    display: inline;
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.medium}) {
    padding: 0 ${(props) => props.theme.modularScale.small};
  }
`;

const Title = styled(Typography)`
  color: ${(props) => props.theme.color.brand};
  text-align: center;
  a {
    font-family: inherit;
    text-decoration: none;
  }
`;

const Subtitle = styled(Typography)`
  color: ${(props) => props.theme.color.brand};
  font-family: ${(props) => props.theme.font.sans};
  letter-spacing: 0.2em;
  text-align: center;
  text-transform: uppercase;
`;

const TaxonIcon = styled(Picture)`
  width: 50px;
  height: 50px;
`;

const ModuleHeader = styled('header', { shouldForwardProp: (prop) => prop !== 'is_slider'})`
  margin-bottom: ${({ isSlider, theme }) => isSlider ? theme.modularScale.twentyFour : '0'};


  @media (min-width: ${({ theme }) => theme.breakpoint.medium}) {
    margin-bottom: 0;
  }
`;

const TaxonProducts = (props) => {
  const path = props.data?.path ?? '';
  // check if path starts with '/' if not add it for Algolia
  const algoliaPath = path.substring(0, 1) === '/' ? path : `/${path}`;
  const sState = helpers.pathToSearchState(algoliaPath);

  const DEFAULT_PROPS = {
    searchClient,
    indexName: process.env.NEXT_PUBLIC_ALGOLIA_PRODUCTS_INDEX
  };

  const productLimit = props.data?.product_limit ? parseInt(props.data?.product_limit, 10) : 4;

  return (
    <Container isSlider={props.data.is_slider}>
      <ModuleHeader isSlider={props.data.is_slider}>
        {props.data?.icon
          && (
            <TaxonIcon
              {...props.data.icon}
              alt={props.data?.icon_alt}
              circle
            />
          )}
        <Title element="h1" like="heading-7">
          {props.data.title_url ? (
            <Link href={props.data.title_url}>
              <a>
                {props.data.title}
              </a>
            </Link>
          ) : props.data.title}
        </Title>
        <Subtitle element="p" like="paragraph-4">
          {props.data.subtitle}
        </Subtitle>
      </ModuleHeader>
      <TaxonProductsAlgolia
        {...DEFAULT_PROPS}
        searchState={sState}
        createURL={(state) => helpers.createURL(state, props)}
        isSlider={props.data.is_slider}
        isAlgoliaPersonalizationEnabled={props.data.is_algolia_personalization}
        style={props.data.style ?? 'circle'}
        productLimit={productLimit}
      />
      {props.data?.hr && <Ruler />}
    </Container>
  );
};

TaxonProducts.propTypes = {
  data: PropTypes.object.isRequired
};

export default TaxonProducts;
