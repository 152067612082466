import buildUrl from 'build-url';
import deconstructURL from '../deconstructPath';

export const TRENDS_MAP = {
  'just-in': 'Just In',
  'on-sale': 'On Sale',
  exclusives: 'Exclusives',
  'all-time-best-sellers': 'All Time Best Sellers',
  monogrammable: 'Monogrammable',
  'best-sellers-this-season': 'Best Sellers This Season',
  'best-sellers': 'Best Sellers',
  'selling-fast': 'Selling Fast',
  'new-this-week': 'New This Week',
  'selling-fast-this-week': 'Selling Fast This Week',
  'selling-fast-today': 'Selling Fast Today',
  'most-wished': 'Most Wished',
  'new-today': 'New Today'
};

export const SORT_ITEMS = {
  best_match: process.env.NEXT_PUBLIC_ALGOLIA_PRODUCTS_INDEX,
  best_sellers: `${process.env.NEXT_PUBLIC_ALGOLIA_PRODUCTS_INDEX}_best_sellers`,
  just_in: `${process.env.NEXT_PUBLIC_ALGOLIA_PRODUCTS_INDEX}_just_in`,
  price_asc: `${process.env.NEXT_PUBLIC_ALGOLIA_PRODUCTS_INDEX}_price_asc`,
  price_desc: `${process.env.NEXT_PUBLIC_ALGOLIA_PRODUCTS_INDEX}_price_desc`
};

export const validRefinements = [
  'variants.age_range',
  'product_type',
  'main_category',
  'occasion',
  'gender',
  'variants.shoe_sizes',
  'variants.clothing_sizes',
  'brand',
  'color',
  'material_type',
  'sleeve_length',
  'variants.maisonette_sale',
  'trends_slug',
  'trends',
  'discount_percent_ranges'
];

export const pathToSearchState = (path) => {
  let range;
  let refinementList;
  let sortBy;
  let toggle;
  let queryObject;
  let pageNo;
  let pageTypeRefinement;

  const {
    pageType,
    hierarchicalMenu: hm,
    pageValue,
    queries
  } = deconstructURL(path);

  const hierarchicalMenu = { 'categories_slug.lvl0': hm ? hm.join(' > ') : '' };

  if (pageType !== 'shop') {
    // Add pageValue data to pageTypeRefinement for edits, brands and trends; this populates
    // the default refinement list for taxon-products and other path-to-searchState components
    switch (pageType) {
      case 'edits':
        pageTypeRefinement = { edits: pageValue };
        break;
      case 'trends':
        pageTypeRefinement = { trends_slug: pageValue };
        break;
      case 'brands':
        pageTypeRefinement = { brand_slug: pageValue };
        break;
      default: // do nothing;
        break;
    }
  }

  if (queries) {
    queryObject = queries;

    if (pageType) {
      if (pageType === 'brands' && queryObject.brand) delete queryObject.brand;
      if (pageType === 'trends' && queryObject.trend) delete queryObject.trend;
    }

    const { isort, page } = queryObject;

    const facetsAreApplied = !!(validRefinements.find((facet) => queryObject[facet]));

    if (facetsAreApplied) {
      const additionalRefinements = {};

      validRefinements.forEach((facet) => {
        if (queryObject[facet]) {
          if (facet === 'variants.maisonette_sale') {
            range = {};
            range['variants.maisonette_sale'] = {};

            if (queryObject[facet].includes('-')) {
              // if there is a hyphen, then this indicates
              // that there is defined min & max
              const [min, max] = queryObject[facet].split('-');

              range['variants.maisonette_sale'] = { min: +min, max: +max };
            } else {
              const [rangeType, value] = queryObject[facet].split(':');
              range['variants.maisonette_sale'][rangeType] = +value;
            }
          } else {
            // if there is a valid facet present,
            // format the values as an array of strings for Algolia's searchState
            // NOTE: if there is a "_" in the facet value,
            // then replace with "+"
            const facetValues = queryObject[facet].split('+').reduce((arr, val) => {
              arr.push(val.replace('%2B', '+'));
              return arr;
            }, []);

            additionalRefinements[facet] = facetValues;
          }
        }
      });

      if (Object.keys(additionalRefinements)?.length) {
        refinementList = { ...refinementList, ...additionalRefinements };
      }
    }

    if (isort) {
      const sortIndex = SORT_ITEMS[isort];
      if (sortIndex) sortBy = sortIndex;
    }

    if (page) {
      pageNo = page;
    }
  }

  const state = {
    ...(queryObject && queryObject.w && { query: queryObject.w }),
    ...(toggle && { toggle }),
    ...(refinementList && { refinementList }),
    ...(hierarchicalMenu && { hierarchicalMenu }),
    ...(sortBy && { sortBy }),
    ...(pageNo && { page: pageNo }),
    ...(range && { range }),
    ...(pageTypeRefinement && { pageTypeRefinement })
  };

  return state;
};

export const createURL = (state, props) => {
  const {
    hierarchicalMenu,
    refinementList = {},
    sortBy,
    range,
    query,
    page
  } = state;

  const priceRange = (() => {
    if (!range) return null;

    const ranges = range['variants.maisonette_sale'];
    if (!ranges.min && !ranges.max) return {};

    let prices = '';

    if (ranges.min && ranges.max) {
      prices = `${ranges.min}-${ranges.max}`;
    }

    if (ranges.min && !ranges.max) {
      prices = `min:${ranges.min}`;
    }

    if (ranges.max && !ranges.min) {
      prices = `max:${ranges.max}`;
    }

    return { 'variants.maisonette_sale': prices };
  })();

  const filters = (() => {
    /*
    * grabs the applied filters from the refinementList and appends the filters with a "+"
    */

    const refinementTypes = Object.keys(refinementList);
    if (priceRange && priceRange['variants.maisonette_sale']) refinementTypes.push('variants.maisonette_sale');
    const activeRefinements = refinementTypes.sort() ?? [];

    if (!activeRefinements.length) return null;

    const formattedFilters = {};

    // we need to append all facet values of each facet type with a "+"
    activeRefinements.forEach((facetType) => {
      if (facetType === 'variants.maisonette_sale') {
        formattedFilters[facetType] = priceRange[facetType];
      } else {
        const facetValues = refinementList[facetType];
        if (!facetValues) return;

        if (facetValues.length === 1) {
          // eslint-disable-next-line prefer-destructuring
          formattedFilters[facetType] = facetValues[0].replace('+', '%2B');
        } else {
          const formattedValues = facetValues.sort().reduce((acc, curr) => {
            const val = curr.replace('+', '%2B');

            if (!acc.length) return acc + val;
            return `${acc}+${val}`;
          }, '');

          formattedFilters[facetType] = formattedValues;
        }
      }
    });

    return formattedFilters;
  })();

  const categories = (() => {
    if (!hierarchicalMenu) return null;

    const cats = hierarchicalMenu['categories_slug.lvl0'].split(' > ');
    return cats.join('/');
  })();

  const sort = (() => {
    if (!sortBy) return null;

    return sortBy === process.env.NEXT_PUBLIC_ALGOLIA_PRODUCTS_INDEX
      ? 'best_match'
      : sortBy?.replace(`${process.env.NEXT_PUBLIC_ALGOLIA_PRODUCTS_INDEX}_`, '');
  })();

  let url = '/shop';
  if (props.brand) url = `/${props.brand.permalink}`;
  if (props.trend) url = `/trends/${props.trend}`;
  if (props.edit) url = `/edits/${props.edit}`;
  if (props.type) url = '/le_scoop';

  const routeQuery = { ...props?.router?.query };

  if (routeQuery) {
    /* this removes all the previous query params */
    if (routeQuery.cats) delete routeQuery.cats;
    if (routeQuery.edit) delete routeQuery.edit;
    if (routeQuery.trend) delete routeQuery.trend;
    if (routeQuery.brand) delete routeQuery.brand;
    if (routeQuery.page) delete routeQuery.page;

    // removes params related to content pillar pages/previews
    if (routeQuery.category) delete routeQuery.category;
    if (routeQuery.subcategory) delete routeQuery.subcategory;
    if (routeQuery.id) delete routeQuery.id;

    // TODO: remove this once all traffic navigates to the new URL
    if (routeQuery.af) delete routeQuery.af;

    validRefinements.forEach((facet) => {
      if (routeQuery[facet]) delete routeQuery[facet];
    });
  }

  const queryQualifications = (query
    || Object.keys(routeQuery).length
    || filters
    || priceRange
    || sort
    || (page && page !== 1));

  let finalUrl = buildUrl(
    url,
    {
      path: categories || undefined,
      ...queryQualifications && {
        queryParams: {
          ...(query && { w: query }),
          ...(!query && routeQuery),
          ...(sort && { isort: sort }),
          ...(filters && { ...filters }),
          ...(page && page !== 1 && page !== '1' && { page })
        }
      }
    }
  );

  if (finalUrl.includes('?')) {
    // remove '?' if there are no queries
    const queries = finalUrl.split('?')[1];
    if (!queries) finalUrl = finalUrl.slice(0, -1);
  }

  return finalUrl;
};
